















































































































































































































































































































import Component, { mixins } from 'vue-class-component';

import formatTime from '../../util/format-time';
import PlayerV3Base from '@/components/players/js/player-v3-base';

@Component
export default class PlayerV3Radial extends mixins(PlayerV3Base) {
  bodyWidth = document.body.clientWidth;

  onKeyDown(e: KeyboardEvent): void {
    if (e.code === 'KeyN' && this.trackIndex! > 2) {
      return;
    }

    return this.onKey(e);
  }

  mounted() {
    window.addEventListener('resize', this.handleResize);
  }

  formatTime(time: number): string {
    return formatTime(time, false);
  }

  toggleInfo() {
    // eslint-disable-next-line
    const classList = document.getElementById('info')!.classList;
    const info = document.querySelector('.info') as HTMLElement;
    const player = document.querySelector('.player') as HTMLElement;
    const footer = document.querySelector('.footer') as HTMLElement;

    if (classList.contains('show')) {
      const openInfoButton = document.querySelector(
        '.info-show'
      ) as HTMLElement;
      const afterClose = () => {
        info.style.visibility = 'hidden';
        info.removeEventListener('transitionend', afterClose);
        openInfoButton.focus();
      };
      info.addEventListener('transitionend', afterClose);
      player.style.visibility = 'visible';
      footer.style.visibility = 'visible';
      player.removeAttribute('aria-hidden');
      footer.removeAttribute('aria-hidden');
      info.setAttribute('aria-hidden', 'true');
      info.classList.remove('show');
    } else {
      const closeInfoButton = document.querySelector(
        '.info-close'
      ) as HTMLElement;
      const afterOpen = () => {
        player.style.visibility = 'hidden';
        footer.style.visibility = 'hidden';
        player?.setAttribute('aria-hidden', 'true');
        footer?.setAttribute('aria-hidden', 'true');
        info.removeEventListener('transitionend', afterOpen);
        closeInfoButton.focus();
      };
      info.addEventListener('transitionend', afterOpen);
      info.style.visibility = 'visible';
      info.classList.add('show');
      info.removeAttribute('aria-hidden');
      closeInfoButton.setAttribute('tabindex', '1');
    }
  }

  handleResize(): void {
    const info = document.querySelector('.info') as HTMLElement;
    const player = document.querySelector('.player') as HTMLElement;
    const footer = document.querySelector('.footer') as HTMLElement;

    // if crossing from mobile to desktop
    if (window.innerWidth >= 660 && this.bodyWidth < 660) {
      player.style.visibility = 'visible';
      footer.style.visibility = 'visible';
      info.style.visibility = 'visible';
      player.removeAttribute('aria-hidden');
      footer.removeAttribute('aria-hidden');
      info.removeAttribute('aria-hidden');
      info.classList.add('show');
      this.toggleMainPlay('off');
    }
    // if crossing from desktop to mobile
    else if (window.innerWidth < 660 && this.bodyWidth >= 660) {
      info.style.visibility = 'hidden';
      info.setAttribute('aria-hidden', 'true');
      info.classList.remove('show');
      this.toggleMainPlay('on');
    }

    this.bodyWidth = document.body.clientWidth;
  }

  get showTrackList(): boolean {
    return document.body.clientWidth >= 660;
  }
}
